import React from 'react';
import CustomerManageSection from '../section/CustomerMamageSection';
import CustomerManageHeaderSection from '../section/CustomerManageHeaderSection';

const pageTitle = 'Product Manage';

// General Parameter of Each Page
// 1. Title Set
// 2. Data Load (From DB) In a single File
// 3. Spinner until load Data

export default function CustomerManagePage() {
    // --Page Build
    document.title = `${pageTitle} || ${process.env.REACT_APP_COMPANY_TITLE}`;
    return (
        <>
            <CustomerManageHeaderSection headTitle="Customer List" />
            <CustomerManageSection />;
        </>
    );
}
