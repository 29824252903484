import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    // eslint-disable-next-line prettier/prettier
    useTheme
} from '@material-ui/core';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const updatingPriceProduct = (params, productId, callback) => {
    AxiosAuth.post(ConfigApi.API_PRODUCT_PRICE_UPDATE.replace(':productId', productId), params)
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log(e);
        });
};

function financial(x) {
    return x.toFixed(2);
}

function vatCalculator(priceX, vatType, vatPercentX) {
    const price = Number.parseFloat(priceX || 0);
    const vatPercent = Number.parseFloat(vatPercentX || 0);

    if (vatType === 'no_vat') {
        return {
            rawPrice: financial(price),
            vatAmount: financial(0),
            totalPrice: financial(price),
        };
    }

    if (vatType === 'include_vat') {
        const rawPrice = (price * 100) / (100 + vatPercent);
        const vat = (price * vatPercent) / (100 + vatPercent);

        return {
            rawPrice: financial(rawPrice),
            vatAmount: financial(vat),
            totalPrice: financial(price),
        };
    }

    if (vatType === 'exclude_vat') {
        const vatAmount = (price * vatPercent) / 100;

        return {
            rawPrice: financial(price),
            vatAmount: financial(vatAmount),
            totalPrice: financial(price + vatAmount),
        };
    }
    return {};
}

function ShowCalculation({ vatType, calculatedData }) {
    if (vatType !== 'no_vat' && calculatedData.totalPrice > 0) {
        return (
            <div className="price-vat-show">
                <span>{calculatedData.rawPrice}</span>
                <span>+</span>
                <span>{calculatedData.vatAmount}</span>
                <span>=</span>
                <span>{calculatedData.totalPrice}</span>
            </div>
        );
    }
    return null;
}

export default function ProductPriceSetModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const deviceClassName = `device-${deviceSize(useTheme())}`;
    const shopInfo = useSelector((state) => state.shopInfo);
    const productDetails = useSelector((state) => state.productDetails);
    const [rawPrice, setRawPrice] = React.useState(productDetails.price_sale);
    const [vatType, setVatType] = React.useState(
        productDetails.vat_type ? productDetails.vat_type : 'no_vat'
    );
    const [vatPercent, setVatPercent] = React.useState(productDetails.vat_percent);

    const dispatch = useDispatch();
    const history = useHistory();

    const handleRawPrice = (event) => {
        setRawPrice(event.target.value);
    };

    const handleVatType = (event) => {
        setVatType(event.target.value);
    };

    const handleVatPercent = (event) => {
        setVatPercent(event.target.value);
    };

    const handleNextClick = () => {
        // console.dir(next);
        updatingPriceProduct(
            {
                productPrice: rawPrice,
                productVatType: vatType,
                productVatPercent: vatPercent,
                shopId: shopInfo.id,
            },
            productDetails.id,
            (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    ProductDataUpdateDispatch(dispatch, {
                        price_sale: rawPrice,
                        vat: vatPercent,
                        vat_tax_type: vatType,
                    });
                    history.push(next);
                }
            }
        );
    };

    const calculatedData = vatCalculator(rawPrice, vatType, vatPercent);

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={productDetails.title}
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Price Setup
                        </Typography>

                        <TextField
                            required
                            label="Product Price"
                            variant="outlined"
                            fullWidth
                            type="number"
                            helperText="Please enter the product price"
                            onChange={handleRawPrice}
                            value={rawPrice}
                            size="small"
                        />

                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="vat"
                                name="vat"
                                value={vatType}
                                onChange={handleVatType}
                            >
                                <FormControlLabel
                                    value="no_vat"
                                    control={<Radio />}
                                    label="No Vat"
                                />
                                <FormControlLabel
                                    value="include_vat"
                                    control={<Radio />}
                                    label="Include Vat"
                                />
                                <FormControlLabel
                                    value="exclude_vat"
                                    control={<Radio />}
                                    label="Exclude Vat"
                                />
                            </RadioGroup>
                        </FormControl>

                        {vatType !== 'no_vat' && (
                            <TextField
                                required
                                label="Product Vat"
                                variant="outlined"
                                fullWidth
                                value={vatPercent}
                                type="number"
                                onChange={handleVatPercent}
                                size="small"
                            />
                        )}
                        <ShowCalculation vatType={vatType} calculatedData={calculatedData} />
                    </div>

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={rawPrice ? [1] : []}
                        skip
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
