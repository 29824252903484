import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useTheme } from '@material-ui/core';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const updatingDetailProduct = (params, productId, callback) => {
    AxiosAuth.post(ConfigApi.API_PRODUCT_DES_UPDATE.replace(':productId', productId.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log(e);
        });
};
export default function ProductDescriptionSetModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const deviceClassName = `device-${deviceSize(useTheme())}`;
    const shopInfo = useSelector((state) => state.shopInfo);
    const productDetails = useSelector((state) => state.productDetails);
    const [descriptionValue, setDescriptionValue] = React.useState(
        productDetails.product_description
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const productId = useParams();

    // const handleValue = (event) => {
    //     setDescriptionValue(event.target.value);
    // };
    const handleNextClick = () => {
        // console.dir(next);
        updatingDetailProduct(
            { product_description: descriptionValue, shopId: shopInfo.id },
            productId,
            (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    // --Update Store
                    ProductDataUpdateDispatch(dispatch, {
                        product_description: descriptionValue,
                    });
                    history.push(next);
                }
            }
        );
    };
    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={productDetails.title}
                    />
                    <div className="app-style-body">
                        <h4 className="mb-2 mt-2 field-title">Product Description</h4>
                        {/* <TextField
                            required
                            label="Product Description"
                            variant="outlined"
                            fullWidth
                            helperText="Please enter the product Description"
                            rows={10}
                            multiline
                            type="textarea"
                            value={descriptionValue}
                            onChange={handleValue}
                        /> */}

                        <CKEditor
                            editor={ClassicEditor}
                            data={descriptionValue}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setDescriptionValue(data);
                            }}
                        />
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={descriptionValue}
                        skip
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
