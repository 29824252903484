import { TextField, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const updateProductTitle = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_PRODUCT_TITLE_UPDATE.replace(':productId', params.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log(e);
        });
};

export default function ProductUpdateTitleModal(props) {
    const { prev, next, step, paths, exiturl } = props;

    const theme = useTheme();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const shopInfo = useSelector((state) => state.shopInfo);
    const productDetails = useSelector((state) => state.productDetails);
    const [titleValue, setTitleValue] = React.useState(productDetails.title);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleValue = (event) => {
        setTitleValue(event.target.value);
    };

    const handleNextClick = () => {
        // console.dir(next);
        updateProductTitle(
            { productTitle: titleValue, id: productDetails.id, shopId: shopInfo.id },
            (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0 && response.data.id) {
                    // --Update Store
                    ProductDataUpdateDispatch(dispatch, {
                        title: titleValue,
                    });
                    history.push(next);
                }
            }
        );
    };

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={titleValue}
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Update Product Name
                        </Typography>
                        <TextField
                            required
                            label="Product Name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the product name"
                            value={titleValue}
                            onChange={handleValue}
                        />
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={titleValue}
                        handleNextClick={handleNextClick}
                        skip
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
