import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CampaignDataDispatch from '../../../dispatches/CampaignDataDispatch';
import Preview from '../../../utils/Preview';
import CampaignDetailSection from '../section/CampaignDetailSection';
import CampaignManageHeaderSection from '../section/CampaignManageHeaderSection';
import CampaignUpdateModals from '../section/CampaignUpdateModals';

export default function CampaignDetailPage() {
    const { id } = useParams();

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const shopInfo = useSelector((state) => state.shopInfo);

    useEffect(() => {
        CampaignDataDispatch(id, dispatch, (campaignDetails) => {
            if (campaignDetails.id !== 0) {
                setShow(true);
            }
        });
    }, [dispatch, id, shopInfo.id]);

    return (
        <Preview placeholderModel="table" show={show}>
            <CampaignManageHeaderSection />
            <CampaignDetailSection />
            <CampaignUpdateModals />
        </Preview>
    );
}
