const initialState = null;

const NavMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_MENU': {
            return action.payload.menuId;
        }
        default:
            return state;
    }
};

export default NavMenuReducer;
