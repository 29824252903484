import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import DetectDevice from './DetectDevice';
import LoadingFullScreen from './LoadingFullScreen';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
        borderRadius: '10px',
        objectFit: 'cover',
        objectPosition: 'top',
    },
    tableContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(33, 43, 54)',
        transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 0,
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    card: {
        maxWidth: '100%',
        margin: theme.spacing(2),
    },
    media: {
        height: 190,
    },
}));

export default function Preview({ children, placeholderModel, show, errorPage }) {
    const classes = useStyles();

    if (errorPage) {
        return errorPage;
    }

    if (show) {
        return children;
    }

    if (placeholderModel === 'app-init') {
        return <LoadingFullScreen />;
    }

    return (
        <DetectDevice>
            <TableContainer className={classes.tableContainer} component={Paper} type="lg">
                <Table className={classes.table} aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow className="tableTr">
                            <TableCell width="30">
                                <Checkbox inputProps={{ 'aria-label': 'secondary checkbox' }} />
                            </TableCell>
                            <TableCell width="96" />
                            <TableCell>Products</TableCell>
                            <TableCell width="8%" align="center">
                                Price
                            </TableCell>
                            <TableCell width="8%" align="center">
                                Offer P.
                            </TableCell>
                            <TableCell width="8%" align="center">
                                Sale
                            </TableCell>
                            <TableCell width="8%" align="center">
                                Stock
                            </TableCell>
                            <TableCell width="8%" align="center">
                                Rating
                            </TableCell>
                            <TableCell width="8%" align="center">
                                Published
                            </TableCell>
                            <TableCell width="8%" align="center">
                                Status
                            </TableCell>
                            <TableCell width="8%" colSpan="2" align="center">
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow className={[classes.tableRowHover, 'tableTr '].join(' ')}>
                            <TableCell width="10" align="center">
                                <Skeleton animation="wave" height={30} width="80%" />
                            </TableCell>

                            <TableCell align="center" />

                            <TableCell align="left">
                                <Skeleton animation="wave" height={50} width={50} />
                            </TableCell>

                            <TableCell align="center">
                                <Skeleton animation="wave" height={10} width="80%" />
                            </TableCell>
                            <TableCell align="center">
                                <Skeleton animation="wave" height={10} width="80%" />
                            </TableCell>
                            <TableCell align="center">
                                <Skeleton animation="wave" height={10} width="80%" />
                            </TableCell>
                            <TableCell align="center">
                                <Skeleton animation="wave" height={10} width="80%" />
                            </TableCell>
                            <TableCell align="center">
                                <Skeleton animation="wave" height={10} width="80%" />
                            </TableCell>
                            <TableCell align="center" />
                            <TableCell align="center">
                                <Skeleton animation="wave" height={10} width="80%" />
                            </TableCell>
                            <TableCell align="center">
                                <Skeleton animation="wave" height={10} width="80%" />
                            </TableCell>
                            <TableCell align="center">
                                <Skeleton animation="wave" height={10} width="80%" />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <div className="row" type="xs|sm">
                <div className="col-12 productMobile-card-col">
                    <Card className={classes.card}>
                        <CardHeader
                            avatar={
                                <Skeleton
                                    animation="wave"
                                    variant="circle"
                                    width={40}
                                    height={40}
                                />
                            }
                            title={
                                <Skeleton
                                    animation="wave"
                                    height={10}
                                    width="80%"
                                    style={{ marginBottom: 6 }}
                                />
                            }
                            subheader={<Skeleton animation="wave" height={10} width="40%" />}
                        />
                        <Skeleton animation="wave" variant="rect" className={classes.media} />

                        <CardContent>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </CardContent>
                    </Card>
                </div>
            </div>
        </DetectDevice>
    );
}
