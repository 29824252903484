import ConfigApi from '../../configs/ConfigApi';
import store from '../store';
import AxiosAuth from '../utils/AxiosAuth';

const shopInfoSet = (payload) => ({
    type: 'SHOP_INFO_SET',
    payload,
});

function categorySet(payload) {
    return {
        type: 'CATEGORY_SET',
        payload,
    };
}

export function ShopInfoUpdateDispatch(dispatch, newData, callback) {
    const { shopInfo } = store.getState();
    const newShopDetails = { ...shopInfo, ...newData };
    dispatch(shopInfoSet(newShopDetails));

    if (typeof callback === 'function') {
        callback(newShopDetails);
    }
}

export default function ShopInfoDispatch(id, dispatch) {
    return new Promise((resolve, reject) => {
        if (!id || store.getState()?.shopInfo?.id !== id) {
            AxiosAuth.get(ConfigApi.API_SHOP_INFO.replace(':shopId', id || 'default'))
                .then((response) => {
                    console.log(response);
                    if (response.data?.shopInfo?.id) {
                        // Shop Info Set to react-redux-store
                        dispatch(shopInfoSet(response.data.shopInfo));

                        // Product Category Categories Set to react-redux-store
                        dispatch(categorySet(response.data.categories));

                        resolve(response.data.shopInfo);
                    } else {
                        reject(response);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        } else {
            resolve(store.getState()?.shopInfo);
        }
    });
}
