import React from 'react';
import '../../assets/css/infinity-logo.css';
import loadingBg from '../../assets/images/loading-bg.jpg';

export default function LoadingFullScreen() {
    return (
        <>
            <img
                src={loadingBg}
                alt="Loading"
                style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'fixed' }}
            />
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    background: 'rgb(58 82 138 / 57%)',
                }}
            >
                <div className="logoPosition">
                    <div className="animatedLogo">
                        <div className="infinityLogoChrome">
                            <div />
                            <div />
                            <div />
                        </div>

                        <div className="infinityLogo">
                            <div>
                                <span />
                            </div>
                            <div>
                                <span />
                            </div>
                            <div>
                                <span />
                            </div>
                        </div>
                    </div>
                    <br />
                    <span style={{ color: '#FFF' }}>Loading...</span>
                </div>
            </div>
        </>
    );
}
