import ConfigApi from '../../configs/ConfigApi';
import store from '../store';
import AxiosAuth from '../utils/AxiosAuth';

function setProductDataToStore(payload) {
    return {
        type: 'PRODUCT_DETAILS_SET',
        payload,
    };
}

export function ProductDataUpdateDispatch(dispatch, newData, callback) {
    const { productDetails } = store.getState();
    const newProductDetails = { ...productDetails, ...newData };
    dispatch(setProductDataToStore(newProductDetails));

    if (typeof callback === 'function') {
        callback(newProductDetails);
    }
}

export default function ProductDataDispatch(id, dispatch, callback) {
    const { productDetails } = store.getState();

    if (productDetails.id !== parseInt(id, 10)) {
        AxiosAuth.get(ConfigApi.API_PRODUCT_DETAILS.replace(':productId', id))
            .then((response) => {
                dispatch(setProductDataToStore(response.data));

                if (typeof callback === 'function') {
                    callback(response.data, response);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    } else if (typeof callback === 'function') {
        callback(productDetails);
    }
}
