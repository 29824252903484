import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {},
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        objectFit: 'cover',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

export default function OrderCard({ data }) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <div className="row">
                    <div className="col-2">
                        <img className="order-card-image" src={data.imgUrl} alt="" />
                    </div>
                    <div className="col-5">
                        <Typography variant="body2" color="textSecondary" component="p">
                            {data.title}
                        </Typography>
                    </div>
                    <div className="col-2">
                        <Typography variant="body2" color="textSecondary" component="p">
                            Paid
                        </Typography>
                    </div>
                    <div className="col-3">
                        <Typography variant="body2" color="textSecondary" component="p">
                            Status
                        </Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}
