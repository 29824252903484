import {
    IconButton,
    ListItemSecondaryAction,
    makeStyles,
    MenuList,
    TextField,
    Typography,
    // eslint-disable-next-line prettier/prettier
    useTheme
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ImageIcon from '@material-ui/icons/Image';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    spacing: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const creatingProduct = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_PRODUCTS, params)
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log(e);
        });
};

const getProductData = (params, callback) => {
    AxiosAuth.get(ConfigApi.API_PRODUCTS, {
        params,
    }).then((response) => {
        if (typeof callback === 'function') {
            callback(response);
        }
    });
};

function RecentProductList({ data, classes, setTitleValue }) {
    return (
        <>
            <Typography variant="h6">Recent Products</Typography>

            <List className={classes.root}>
                {data.length > 0 &&
                    data?.map((row) => (
                        <ListItem key={row.id}>
                            <ListItemAvatar>
                                <Avatar>
                                    <ImageIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={row.title} secondary="Jan 9, 2014" />

                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">
                                    <MenuList />
                                </IconButton>
                            </ListItemSecondaryAction>

                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => {
                                        setTitleValue(row.title);
                                    }}
                                >
                                    <FileCopyIcon value={row.title} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
            </List>
        </>
    );
}

export default function ProductCreateModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const shopInfo = useSelector((state) => state.shopInfo);

    const classes = useStyles();
    const theme = useTheme();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const [data, setData] = useState([]);
    const [titleValue, setTitleValue] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();

    const handleValue = (event) => {
        setTitleValue(event.target.value);
    };

    const handleNextClick = () => {
        creatingProduct({ productName: titleValue, shopId: shopInfo.id }, (response) => {
            DataErrors(response, dispatch);
            if (response.data.error === 0 && response.data.id) {
                history.push(next.replace(':id', response.data.id));
            }
        });
    };

    useEffect(() => {
        getProductData({ shopId: shopInfo.id }, (response) => {
            setData(response.data);
        });
    }, [shopInfo.id, titleValue]);

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={titleValue}
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Name of the Product
                        </Typography>
                        <TextField
                            required
                            label="Product Name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the product name"
                            value={titleValue}
                            onChange={handleValue}
                        />

                        <div className="mt-3">
                            {data.length >= 1 && (
                                <RecentProductList
                                    data={data.slice(0, 5)}
                                    classes={classes}
                                    setTitleValue={setTitleValue}
                                />
                            )}
                        </div>
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={titleValue}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
