import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LoginPage from './app/auth/LoginPage';
import OrderInvoicePrintPdf from './app/orders/comps/print/OrderInvoicePrintPdf';
import ErrorPage from './app/system/ErrorPage';
import BackdropCommon from './components/basic/BackdropCommon';
import SnackbarCommon from './components/basic/SnackbarCommon';
import ShopRouters from './routes/ShopRouters';
import UserRoutes from './routes/UserRouters';

export default function RouteHandler() {
    return (
        <BrowserRouter>
            <SnackbarCommon />
            <BackdropCommon />

            <Switch>
                <Route path="/login" exact>
                    <LoginPage />
                </Route>

                <Route path="/print" exact>
                    <OrderInvoicePrintPdf />
                </Route>

                <Route path="/error">
                    <ErrorPage />
                </Route>

                <Route path="/user/">
                    <UserRoutes />
                </Route>

                <Route path="/:shopId">
                    <ShopRouters />
                </Route>

                <Route path="/" exact>
                    <ShopRouters />
                </Route>

                <Route path="*">
                    <ErrorPage />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}
