import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FinanceManagePage from '../app/finance/FinanceManagePage';

export default function FinanceRouters() {
    return (
        <Switch>
            <Route path="/:shopId/finance/manage">
                <FinanceManagePage />
            </Route>
        </Switch>
    );
}
