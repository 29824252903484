import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import StepHandler from '../../../utils/StepHandler';
import ProductCreateModal from '../modals/ProductCreateModal';

export default function ProductManageModals() {
    const location = useLocation();
    const shopInfo = useSelector((state) => state.shopInfo);

    // const handlePrev = () => {};
    // const handleNext = () => {};

    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopInfo.id}/products/manage/`}>
            <ProductCreateModal path={`/${shopInfo.id}/products/manage/create/`} />
            <div path={`/${shopInfo.id}/products/:id/update/category/`} />
            <div path={`/${shopInfo.id}/products/:id/update/description/`} />
            <div path={`/${shopInfo.id}/products/:id/update/prices/`} />
            <div path={`/${shopInfo.id}/products/:id/update/images/`} />
            <div path={`/${shopInfo.id}/products/:id/update/variation/`} />
        </StepHandler>
    );
}
