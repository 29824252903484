import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import StepHandler from '../../../utils/StepHandler';
import ShopAddressModal from '../modals/ShopAddressModal';
import ShopContactModal from '../modals/ShopContactModal';
import ShopDescriptionSetModal from '../modals/ShopDescriptionSetModal';
import ShopImageSetModal from '../modals/ShopImageSetModal';
import ShopUpdateTitleModal from '../modals/ShopUpdateTitleModal';

export default function ShopUpdateModals() {
    const location = useLocation();
    const { shopId } = useParams();

    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopId}/overview/`}>
            <ShopUpdateTitleModal path={`/${shopId}/update/title/`} />
            <ShopContactModal path={`/${shopId}/update/contact/`} />
            <ShopDescriptionSetModal path={`/${shopId}/update/description/`} />
            <ShopAddressModal path={`/${shopId}/update/address/`} />
            <ShopImageSetModal path={`/${shopId}/update/images/`} />
        </StepHandler>
    );
}
