import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CustomerManagePage from '../app/customers/pages/CustomerManagePage';

export default function CustomerRouters() {
    return (
        <Switch>
            <Route path="/:shopId/customers/manage">
                <CustomerManagePage />
            </Route>
        </Switch>
    );
}
