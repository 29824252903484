const initialState = [
    // {
    //     categoryId: 10000001,
    //     category: "Men"
    //     category_sub1: "T-Shirt"
    //     category_sub1: "Half Sleeve"
    //     view: "Half Sleeve T-Shirt of Men's"
    //     icon: ""
    // },
    // {
    //     categoryId: 10000002,
    //     category: "Men"
    //     category_sub1: "T-Shirt"
    //     category_sub1: "Half Sleeve"
    //     view: "Polo Half Sleeve T-Shirt of Men's"
    //     icon: ""
    // }
];

const CategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CATEGORY_SET': {
            return action.payload;
        }
        default:
            return state;
    }
};

export default CategoryReducer;
