/* eslint-disable global-require */
import TabPanel from '@material-ui/lab/TabPanel';
import React from 'react';
import ProductManageSection from '../../../products/sections/ProductManageSection';

export default function CampaignPropertyProduct({ productData }) {
    return (
        <TabPanel value="1">
            <ProductManageSection data={productData} />
        </TabPanel>
    );
}
