import React from 'react';
import DashboardStoreSelectSection from '../../components/section/DashboardStoreSelectSection';
import ShopManageHeaderSection from '../shops/sections/ShopManageHeaderSection';

// General Parameter of Each Page
// 1. Title Set
// 2. Data Load (From DB) In a single File
// 3. Spinner until load Data

export default function IndexPage() {
    // --Page Build
    document.title = process.env.REACT_APP_COMPANY_TITLE;

    return (
        <div>
            <ShopManageHeaderSection />
            <DashboardStoreSelectSection />
        </div>
    );
}
