/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
const ProductData = [
    {
        id: 1001,
        title: 'MeLADIES JACKET',
        stock: 10,
        price: '1500',
        imgUrl: 'http://saralifestyle.com.bd/Files/Banner/75e484c5-6a6a-4b8b-853f-d926b6893743.jpg',
    },
    {
        id: 1002,
        title: 'LADIES ETHNIC 3 PCS',
        stock: 0,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/7423f08f-b100-45e6-b0c4-1fa43c7a546a.jpg',
    },
    {
        id: 1003,
        title: 'LADIES ETHNIC 3 PCS',
        stock: 10,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/fd0aa804-cb1f-4c39-a362-0d0674fc80c2.jpg',
    },
    {
        id: 1004,
        title: 'LADIES ENTHNIC',
        stock: 5,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/9b2c7f66-e0c3-4200-874a-f3d6c84fb597.jpg',
    },
    {
        id: 1005,
        title: 'LADIES SINGLE ETHNIC',
        stock: 8,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/9a721ad9-09b7-4e45-9768-b693e35f7f90.jpg',
    },
    {
        id: 1006,
        title: 'KIDS FROCK',
        stock: 10,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/d864dfee-c06b-49c7-9e1b-9ad7ef1b5e70.jpg',
    },
    {
        id: 1007,
        title: 'Mens Formal Shirt',
        stock: 0,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/2110955d-aa82-467f-8628-8b3691539a2f.jpg',
    },
    {
        id: 1008,
        title: 'MENS PANJABI',
        stock: 10,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/06eaa646-8eaa-493d-b534-2baefd59c8ae.jpg',
    },
    {
        id: 1009,
        title: 'MENS PANJABI',
        stock: 10,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/7b859704-c423-4eb6-af31-ea678d1761ef.jpg',
    },
    {
        id: 1010,
        title: 'MENS PANJABI',
        stock: 50,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/72daa75a-e864-48d6-a4c4-d6700a582117.jpg',
    },
    {
        id: 1011,
        title: 'MENS PANJABI',
        stock: 50,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/0808a55b-5cb6-4077-adaf-55ddf5b2ca60.jpg',
    },
    {
        id: 1012,
        title: 'MENS PANJABI',
        stock: 50,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/b5ff4aad-d673-4b21-98c4-a9fb0c82afc2.jpg',
    },
    {
        id: 1013,
        title: 'MENS PANJABI',
        stock: 20,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/388fb394-fffe-4d41-9c73-114f29ea1c44.jpg',
    },
    {
        id: 1014,
        title: 'MENS PANJABI',
        stock: 10,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/f69a30ab-fd59-4fca-93a2-44f774216111.jpg',
    },
    {
        id: 1015,
        title: 'MENS PANJABI',
        stock: 20,
        price: '1500',
        imgUrl:
            'http://saralifestyle.com.bd/Files/ImageProductMain/3e9f552e-b273-4905-8243-4a6773b67d17.jpg',
    },
];

export default ProductData;
