/* eslint-disable global-require */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import '../../../../assets/css/style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import IsLoggedIn from '../../../utils/IsLoggedIn';
import ShopDetailOverViewInfo from './shopDetail/ShopDetailOverViewInfo';
import ShopDetailPropertyInfo from './shopDetail/ShopDetailPropertyInfo';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    rootTab: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: '20px',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    tabStyle: {
        color: 'black',
    },
}));

const getProductData = (params, callback) => {
    AxiosAuth.get(ConfigApi.API_PRODUCTS, {
        params,
    })
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log(e);
        });
};
export default function ShopDetailSection() {
    const shopDetails = useSelector((state) => state.shopInfo);

    const classes = useStyles();
    const rowsPerPage = 1;
    const [pageNo] = React.useState(0);
    const dispatch = useDispatch();
    const [productData, setProductData] = React.useState([]);

    const history = useHistory();

    React.useEffect(() => {
        // Collect API Data
        getProductData(
            {
                pageNo,
                rowsPerPage,
            },
            (response) => {
                if (IsLoggedIn(response, history)) {
                    setProductData(Array.isArray(response.data) ? response.data : []);
                }
            }
        );
    }, [dispatch, history, pageNo]);

    return (
        <>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <ShopDetailOverViewInfo shopDetails={shopDetails} />
                    <ShopDetailPropertyInfo shopDetails={shopDetails} productData={productData} />
                </Grid>
            </div>
        </>
    );
}
