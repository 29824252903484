import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import StepHandler from '../../../utils/StepHandler';
import CampaignDescriptionSetModal from '../modals/CampaignDescriptionSetModal';
import CampaignDiscountSetModal from '../modals/CampaignDiscountSetModal';
import CampaignDurationModal from '../modals/CampaignDurationModal';
import CampaignImageSetModal from '../modals/CampaignImageSetModal';
import CampaignProductSetModal from '../modals/CampaignProductSetModal';
import CampaignUpdateTitleModal from '../modals/CampaignUpdateTitleModal';

export default function CampaignUpdateModals() {
    const location = useLocation();
    const shopDetails = useSelector((state) => state.shopInfo);
    const { id } = useParams();
    return (
        <StepHandler
            pathname={location.pathname}
            exiturl={`/${shopDetails.id}/campaigns/${id}/detail/`}
        >
            <CampaignUpdateTitleModal path={`/${shopDetails.id}/campaigns/${id}/update/title/`} />
            <CampaignDurationModal path={`/${shopDetails.id}/campaigns/${id}/update/duration/`} />
            <CampaignDiscountSetModal
                path={`/${shopDetails.id}/campaigns/${id}/update/discount/`}
            />
            <CampaignDescriptionSetModal
                path={`/${shopDetails.id}/campaigns/${id}/update/description/`}
            />
            <CampaignImageSetModal path={`/${shopDetails.id}/campaigns/${id}/update/images/`} />
            <CampaignProductSetModal path={`/${shopDetails.id}/campaigns/${id}/update/products/`} />
        </StepHandler>
    );
}
