import { Switch } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfigApi from '../../../../configs/ConfigApi';
import BackdropOpenDispatch from '../../../dispatches/BackdropOpenDispatch';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';

const updateProductPublish = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_PRODUCT_PUBLISH_UPDATE.replace(':productId', params.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log(e);
        });
};

export default function ProductPublishBtn({ data }) {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(!!data.is_published);
    const shopInfo = useSelector((state) => state.shopInfo);

    const handleChange = () => {
        const newVal = value !== true;
        BackdropOpenDispatch(dispatch, true);

        updateProductPublish(
            { is_published: newVal, id: data.id, shopId: shopInfo.id },
            (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0 && response.data.id) {
                    // --Update Store
                    ProductDataUpdateDispatch(dispatch, {
                        is_published: value,
                    });
                    setValue(newVal);
                }
                BackdropOpenDispatch(dispatch, false);
            }
        );
    };

    return (
        <Switch
            checked={value}
            onChange={handleChange}
            color="primary"
            name="checkedB"
            value={value}
            inputProps={{ 'aria-label': 'primary checkbox' }}
        />
    );
}
