import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BreadcrumbsCommon from '../../../components/basic/BreadcrumbsCommon';

export default function ProductPageHeaderSection() {
    const shopInfo = useSelector((state) => state.shopInfo);
    return (
        <div style={{ overflow: 'hidden' }}>
            <Link to={`/${shopInfo.id}/products/manage/create/`}>
                <Button variant="contained" color="secondary" style={{ float: 'right' }}>
                    <AddIcon style={{ marginRight: '8px' }} />
                    Create New
                </Button>
            </Link>

            <div className="page-common-title">Products List</div>
            <BreadcrumbsCommon
                options={[
                    {
                        href: `/${shopInfo.id}/products/`,
                        title: 'Products',
                    },
                    {
                        href: `/${shopInfo.id}/products/manage/`,
                        title: 'Manage',
                    },
                ]}
            />
        </div>
    );
}
