/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import ReportManagePage from '../app/reports/pages/ReportManagePage';
import SettingManagePage from '../app/settings/pages/SettingManagePage';
import ShopOverviewPage from '../app/shops/pages/ShopOverviewPage';
import ErrorPage from '../app/system/ErrorPage';
import IndexPage from '../app/system/IndexPage';
import AppBarSection from '../components/section/AppBarSection';
import MainSection from '../components/section/MainSection';
import NavigationDrawerSection from '../components/section/NavigationDrawerSection';
import NavDrawerToggleDispatch from '../dispatches/NavDrawerToggleDispatch';
import ShopInfoDispatch from '../dispatches/ShopInfoDispatch';
import store from '../store';
import IsLoggedIn from '../utils/IsLoggedIn';
import Preview from '../utils/Preview';
import CampaignRouters from './CampaignRouters';
import CustomerRouters from './CustomerRouters';
import FinanceRouters from './FinanceRouters';
import OrderRouters from './OrderRouters';
import ProductRouters from './ProductRouters';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

export default function ShopRouters() {
    const dispatch = useDispatch();
    NavDrawerToggleDispatch(dispatch, false);

    const params = useParams();
    const classes = useStyles();
    const { pathname } = useLocation();
    const history = useHistory();
    const shopId = params.shopId ? params.shopId : store.getState().shopInfo.id;
    const [open, setOpen] = useState(false);
    const [errorPage, setErrorPage] = useState(null);

    useEffect(() => {
        // BackdropOpenDispatch(dispatch, true);
        ShopInfoDispatch(shopId, dispatch)
            .then((responsesShopInfo) => {
                if (shopId && responsesShopInfo.id && shopId !== responsesShopInfo.id) {
                    history.push(pathname.replace(shopId, responsesShopInfo.id));
                } else {
                    setOpen(true);
                }
                setErrorPage(null);
            })
            .catch((response) => {
                if (response) {
                    IsLoggedIn(response, history);
                } else {
                    setErrorPage(<ErrorPage />);
                }
            });
    }, [dispatch, history, pathname, shopId]);

    return (
        <Preview placeholderModel="app-init" show={open} errorPage={errorPage}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopId={shopId} />
                <NavigationDrawerSection shopId={shopId} />

                <MainSection>
                    <Switch>
                        <Route path="/" exact>
                            <IndexPage />
                        </Route>

                        <Route path="/:shopId/overview">
                            <ShopOverviewPage />
                        </Route>

                        <Route path="/:shopId/update/:modals">
                            <ShopOverviewPage />
                        </Route>

                        <Route path="/:shopId/products">
                            <ProductRouters />
                        </Route>

                        <Route path="/:shopId/orders">
                            <OrderRouters />
                        </Route>

                        <Route path="/:shopId/customers">
                            <CustomerRouters />
                        </Route>

                        <Route path="/:shopId/campaigns">
                            <CampaignRouters />
                        </Route>
                        <Route path="/:shopId/finance">
                            <FinanceRouters />
                        </Route>

                        <Route path="/:shopId/reports">
                            <ReportManagePage />
                        </Route>

                        <Route path="/:shopId/settings">
                            <SettingManagePage />
                        </Route>

                        <Route path="*">
                            <ErrorPage />
                        </Route>
                    </Switch>
                </MainSection>
            </div>
        </Preview>
    );
}
