import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function CampaignManageHeaderSection() {
    const shopInfo = useSelector((state) => state.shopInfo);
    return (
        <div style={{ overflow: 'hidden' }}>
            <Link to={`/${shopInfo.id}/campaigns/create/`}>
                <Button variant="contained" color="secondary" style={{ float: 'right' }}>
                    <AddIcon style={{ marginRight: '8px' }} />
                    Create New
                </Button>
            </Link>

            <div className="page-common-title">Campaign List</div>
            <hr />
        </div>
    );
}
