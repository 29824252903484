import React from 'react';
import { useSelector } from 'react-redux';
import BreadcrumbsCommon from '../../../components/basic/BreadcrumbsCommon';

export default function ProductPageCategoryHeaderSection() {
    const shopInfo = useSelector((state) => state.shopInfo);
    return (
        <div style={{ overflow: 'hidden' }}>
            <div className="page-common-title">Products Category</div>
            <BreadcrumbsCommon
                options={[
                    {
                        href: `/${shopInfo.id}/products/`,
                        title: 'Category',
                    },
                    {
                        href: `/${shopInfo.id}/products/manage/`,
                        title: 'Manage',
                    },
                ]}
            />
        </div>
    );
}
