import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ExpandLess } from '@material-ui/icons';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { default as MenuAll } from '../../../../configs/MenuData';
import ReplaceMap from '../../../utils/ReplaceMap';
import NavDrawerHeader from './NavDrawerHeader';

const activeMenu = {};

function BuildMenuUl({ params, SubMenuClass, MenuData, label, handelMenuClick }) {
    return (
        <List className={[SubMenuClass, `MenuLabel${label}`].join(' ')}>
            {MenuData.map((item) => (
                <BuildMenuLi
                    item={item}
                    key={item.sl}
                    label={label}
                    handelMenuClick={handelMenuClick}
                    params={params}
                />
            ))}
        </List>
    );
}

function BuildMenuLi({
    params,
    item: { sl, icon, title, route, subMenu, subMenuClass },
    label,
    handelMenuClick,
}) {
    let expandable = '';
    if (subMenu) {
        if (activeMenu[label] === sl) {
            expandable = <ExpandLess />;
        } else {
            expandable = <ExpandMore />;
        }
    }

    return (
        <>
            <Link
                to={ReplaceMap(route, params)}
                onClick={(e) => {
                    if (subMenu) {
                        e.preventDefault();
                    }
                }}
            >
                <ListItem
                    button
                    onClick={() => {
                        handelMenuClick(sl, label);
                    }}
                >
                    <ListItemIcon>{icon || <NavigateNext />}</ListItemIcon>
                    <ListItemText primary={title} />
                    {expandable}
                </ListItem>
            </Link>

            {subMenu && (
                <Collapse in={activeMenu[label] === sl} timeout="auto" unmountOnExit>
                    <BuildMenuUl
                        MenuData={subMenu}
                        SubMenuClass={subMenuClass}
                        label={label + 1}
                        handelMenuClick={handelMenuClick}
                        params={params}
                    />
                </Collapse>
            )}
        </>
    );
}

export default function NavDrawerItems({ shopId }) {
    const setMenuSt = useState(null)[1];

    const handelMenuClick = (menuId, label) => {
        activeMenu[label] = menuId;
        setMenuSt(menuId);
    };

    return (
        <div>
            <NavDrawerHeader />
            <Divider />
            <BuildMenuUl
                MenuData={MenuAll}
                SubMenuClass="menu-label-0"
                label={0}
                handelMenuClick={handelMenuClick}
                params={{ shopId }}
            />
            <Divider />
        </div>
    );
}
