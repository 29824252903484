import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import StepHandler from '../../../utils/StepHandler';
import ProductCategorySetModal from '../modals/ProductCategorySetModal';
import ProductDescriptionSetModal from '../modals/ProductDescriptionSetModal';
import ProductImageSetModal from '../modals/ProductImageSetModal';
import ProductPriceSetModal from '../modals/ProductPriceSetModal';
import ProductUpdateTitleModal from '../modals/ProductUpdateTitleModal';
import ProductVariationModal from '../modals/ProductVariationModal';

export default function ProductUpdateModals() {
    const location = useLocation();
    const { id } = useParams();
    const shopInfo = useSelector((state) => state.shopInfo);

    return (
        <StepHandler
            pathname={location.pathname}
            exiturl={`/${shopInfo.id}/products/${id}/details/`}
        >
            <ProductUpdateTitleModal path={`/${shopInfo.id}/products/${id}/update/title/`} />
            <ProductCategorySetModal path={`/${shopInfo.id}/products/${id}/update/category/`} />
            <ProductDescriptionSetModal
                path={`/${shopInfo.id}/products/${id}/update/description/`}
            />
            <ProductPriceSetModal path={`/${shopInfo.id}/products/${id}/update/prices/`} />
            <ProductImageSetModal path={`/${shopInfo.id}/products/${id}/update/images/`} />
            <ProductVariationModal path={`/${shopInfo.id}/products/${id}/update/variation/`} />
        </StepHandler>
    );
}
