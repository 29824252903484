/* eslint-disable global-require */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import '../../../../assets/css/modal-app-style.css';
import '../../../../assets/css/style.css';
import CampaignDetailOverViewInfo from './campaignDetail/CampaignDetailOverViewInfo';
import CampaignDetailPropertyInfo from './campaignDetail/CampaignDetailPropertyInfo';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    rootTab: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: '20px',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    tabStyle: {
        color: 'black',
    },
}));

export default function CampaignDetailSection() {
    const campaignDetail = useSelector((state) => state.campaignDetail);
    console.log(campaignDetail);
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <CampaignDetailOverViewInfo campaignDetail={campaignDetail} />
                    <CampaignDetailPropertyInfo
                        campaignDetail={campaignDetail}
                        productData={campaignDetail.productList}
                    />
                </Grid>
            </div>
        </>
    );
}
