/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ShopInfoDispatch from '../../../dispatches/ShopInfoDispatch';
import Preview from '../../../utils/Preview';
import ShopDetailSection from '../sections/ShopDetailSection';
import ShopUpdateModals from '../sections/ShopUpdateModals';

export default function ShopOverviewPage() {
    const { shopId } = useParams();
    const dispatch = useDispatch();
    const [shopInfo, setShopInfo] = useState({});

    useEffect(() => {
        ShopInfoDispatch(shopId, dispatch)
            .then((shopInfoData) => {
                // setShopInfo(shopInfoData);
                setShopInfo(shopInfoData);
            })
            .catch((err, message) => {
                console.log(message);
            });
    }, [dispatch, shopId]);

    return (
        <Preview placeholderModel="table" show={shopInfo.id && shopInfo.id.toString() === shopId}>
            <ShopDetailSection />
            <ShopUpdateModals />
        </Preview>
    );
}
