/* eslint-disable class-methods-use-this */
import axios from 'axios';
import IsLoggedIn from './IsLoggedIn';

class AxiosAuth {
    setAuth() {
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Secure-Access'] = localStorage.getItem('Secure-Access');
    }

    get(url, headers) {
        this.setAuth();

        return new Promise((resolve, reject) => {
            axios
                .get(url, headers)
                .then((response) => {
                    const isLoggedIn = IsLoggedIn(response);
                    resolve(response, isLoggedIn);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    post(url, data, headers) {
        this.setAuth();

        return new Promise((resolve, reject) => {
            axios
                .post(url, data, headers)
                .then((response) => {
                    const isLoggedIn = IsLoggedIn(response);
                    resolve(response, isLoggedIn);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}
export default new AxiosAuth();
