import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import IsLoggedIn from '../../../utils/IsLoggedIn';
import OrderManageListSection from '../section/OrderManageListSection';
import OrderPageHeaderSection from '../section/OrderPageHeaderSection';

const pageTitle = 'Product Manage';
const rowsPerPage = 100;

const getProductData = (params, callback) => {
    AxiosAuth.get(ConfigApi.API_PRODUCTS, {
        params,
    })
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log(e);
        });
};
export default function OrderManageCanceledPage() {
    const [pageNo] = useState(0);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const history = useHistory();
    // --Page Build
    React.useEffect(() => {
        // Collect API Data
        getProductData(
            {
                pageNo,
                rowsPerPage,
            },
            (response) => {
                // DataErrors(response, dispatch);

                if (IsLoggedIn(response, history)) {
                    setData(response.data);
                }
            }
        );
    }, [dispatch, history, pageNo]);

    document.title = `${pageTitle} || ${process.env.REACT_APP_COMPANY_TITLE}`;
    return (
        <>
            <OrderPageHeaderSection headTitle="Cancel Oreder List" />
            <OrderManageListSection data={data} />
        </>
    );
}
