import React from 'react';
import BreadcrumbsCommon from '../../../components/basic/BreadcrumbsCommon';

export default function OrderPageHeaderSection({ headTitle }) {
    return (
        <div style={{ overflow: 'hidden' }}>
            {/* <Link to="/products/manage/create/">
                <Button variant="contained" color="secondary" style={{ float: 'right' }}>
                    <AddIcon style={{ marginRight: '8px' }} />
                    Create New
                </Button>
            </Link> */}

            <div className="page-common-title">{headTitle}</div>
            <BreadcrumbsCommon
                options={[
                    {
                        href: '/orders/',
                        title: 'Orders',
                    },
                    {
                        href: '/products/manage/',
                        title: 'Manage',
                    },
                ]}
            />
        </div>
    );
}
