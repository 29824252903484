export const DOMAIN = 'https://store-api.bikiran.com/manage';

const ConfigApi = {
    API_SITE_DATA: `${DOMAIN}/site-data.json`,

    // Login Page
    API_LOGGING_IN: `${DOMAIN}/login/`,

    // --Shop Info
    API_SHOP_INFO: `${DOMAIN}/shop/:shopId/info`,

    // Product Module

    API_PRODUCTS: `${DOMAIN}/products/list/`,

    API_PRODUCT_VARIATION_LIST: `${DOMAIN}/products/variation/list/`,
    API_PRODUCT_DETAILS: `${DOMAIN}/products/:productId/details/`,
    API_PRODUCT_TITLE_UPDATE: `${DOMAIN}/products/:productId/update/title`,
    API_PRODUCT_CAT_UPDATE: `${DOMAIN}/products/:productId/update/category`,
    API_PRODUCT_DES_UPDATE: `${DOMAIN}/products/:productId/update/description`,
    API_PRODUCT_PRICE_UPDATE: `${DOMAIN}/products/:productId/update/price`,
    API_PRODUCT_IMG_UPDATE: `${DOMAIN}/products/:productId/update/images`,
    API_PRODUCT_VARIATION_HEAD_UPDATE: `${DOMAIN}/products/:productId/update/variation-header`,
    API_PRODUCT_PUBLISH_UPDATE: `${DOMAIN}/products/:productId/update/publish`,
    API_PRODUCT_EXCLUSIVE_UPDATE: `${DOMAIN}/products/:productId/update/exclusive`,

    // --Image Upload
    API_IMAGE_UPLOAD: `${DOMAIN}/images/upload`,

    // --Category Paths
    API_CATEGORY_LIST: `${DOMAIN}/products/category`,

    // --Shop Module
    API_SHOP: `${DOMAIN}/shop/list`,
    API_SHOP_CREATE: `${DOMAIN}/shop/create`,
    API_SHOP_DETAIL: `${DOMAIN}/shop/:shopId/detail`,
    API_SHOP_UPDATE_TITLE: `${DOMAIN}/shop/:shopId/update/title`,
    API_SHOP_UPDATE_CONTACT: `${DOMAIN}/shop/:shopId/update/contact`,
    API_SHOP_UPDATE_DETAIL: `${DOMAIN}/shop/:shopId/update/detail`,
    API_SHOP_UPDATE_ADDRESS: `${DOMAIN}/shop/:shopId/update/address`,
    API_SHOP_UPDATE_IMAGE: `${DOMAIN}/shop/:shopId/update/image`,

    // --Campaign Module
    API_CAMPAIGN: `${DOMAIN}/campaign/:shopId/list`,
    API_CAMPAIGN_DETAILS: `${DOMAIN}/campaign/:campId/details`,
    API_CAMPAIGN_CREATE: `${DOMAIN}/campaign/:shopId/create`,
    API_CAMPAIGN_UPDATE_TITLE: `${DOMAIN}/campaign/:campId/update/title`,
    API_CAMPAIGN_UPDATE_DURATION: `${DOMAIN}/campaign/:campId/update/duration`,
    API_CAMPAIGN_UPDATE_DISCOUNT: `${DOMAIN}/campaign/:campId/update/discount`,
    API_CAMPAIGN_UPDATE_DESCRUPTION: `${DOMAIN}/campaign/:campId/update/description`,
    API_CAMPAIGN_UPDATE_IMAGE: `${DOMAIN}/campaign/:campId/update/image`,
    API_CAMPAIGN_ADD_PRODUCTS: `${DOMAIN}/campaign/:campId/update/products`,
};

export default ConfigApi;
