/* eslint-disable prettier/prettier */
import { TextField, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ShopInfoUpdateDispatch } from '../../../dispatches/ShopInfoDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import {
    ProductModalFooter,
    ProductModalHeader
} from '../../products/modals/comp/ProductModalHeaderFooter';

const updatingShopContact = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_SHOP_UPDATE_CONTACT.replace(':shopId', params.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log(e);
        });
};

export default function ShopContactModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const deviceClassName = `device-${deviceSize(useTheme())}`;
    const shopDetails = useSelector((state) => state.shopInfo);

    const dispatch = useDispatch();
    const history = useHistory();

    const [shopEmail, setShopEmail] = useState(shopDetails.email_address);
    const [shopMobile, setShopMobile] = useState(shopDetails.contact_number);
    const handleShopEmail = (event) => {
        setShopEmail(event.target.value);
    };
    const handleShopMobile = (event) => {
        setShopMobile(event.target.value);
    };
    const handleNextClick = () => {
        // console.dir(next);
        updatingShopContact({ email: shopEmail, mobile: shopMobile ,id: shopDetails.id}, (response) => {
            DataErrors(response, dispatch);
            if (response.data.error === 0) {
                ShopInfoUpdateDispatch(dispatch, {
                    email_address: shopEmail,
                    contact_number: shopMobile,
                });
                history.push(next);
            }
        });
    };

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader prev={prev} step={step} exiturl={exiturl} title={shopDetails.shop_name}/>
                    <div className="app-style-body">
                        <h4 className="mb-3 mt-2 field-title">Contact of your Shop.</h4>
                        <TextField
                            required
                            label="Email"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the shop email"
                            value={shopEmail}
                            onChange={handleShopEmail}
                            type="email"
                            style={{paddingBottom:'20px'}}
                        />
                        <TextField
                            required
                            label="Mobile Number"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the shop name"
                            value={shopMobile}
                            onChange={handleShopMobile}
                           
                        />
                    </div>

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={shopMobile}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
