import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import IsLoggedIn from '../../../utils/IsLoggedIn';
import Preview from '../../../utils/Preview';
import ProductManageModals from '../sections/ProductManageModals';
import ProductManageSection from '../sections/ProductManageSection';
import ProductPageHeaderSection from '../sections/ProductPageHeaderSection';

const pageTitle = 'Product Manage';

const apiParams = {
    rows: 100,
    'page-no': 1,
};

const getProductData = (params, callback) => {
    AxiosAuth.get(ConfigApi.API_PRODUCTS, {
        params,
    })
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log(e);
        });
};

// General Parameter of Each Page
// 1. Title Set
// 2. Data Load (From DB) In a single File
// 3. Spinner until load Data

export default function ProductManagePage() {
    // --Page Build
    document.title = `${pageTitle} || ${process.env.REACT_APP_COMPANY_TITLE}`;

    const history = useHistory();
    const { shopId } = useParams();
    const { search } = useLocation();

    const [data, setData] = useState(null);

    useEffect(() => {
        setData(null);
        const urlParams = qs.parse(search);

        // Collect API Data
        getProductData(
            {
                shopId,
                ...apiParams,
                ...urlParams,
            },
            (response) => {
                if (IsLoggedIn(response, history)) {
                    setData(response.data);
                }
            }
        );
    }, [history, search, shopId]);

    return (
        <Preview placeholderModel="table" show={data !== null}>
            <ProductPageHeaderSection />
            <ProductManageSection data={data === null ? [] : data} />
            <ProductManageModals />
        </Preview>
    );
}
