import { TextField, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';
import VariationOption from './comp/VariationOption';

const updatingVariantProduct = (params, productId, callback) => {
    AxiosAuth.post(
        ConfigApi.API_PRODUCT_VARIATION_HEAD_UPDATE.replace(':productId', productId.id),
        params
    )
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log(e);
        });
};
const getVariationList = (callback) => {
    AxiosAuth.get(ConfigApi.API_PRODUCT_VARIATION_LIST, {})
        .then((response) => {
            if (typeof callback === 'function') {
                callback(response);
            }
        })
        .catch((e) => {
            console.log(e);
        });
};

const filter = (variationList, target, setVariationListFiltered) => {
    const newList = {};
    Object.keys(variationList).map((key) => {
        const newArray = [];
        newList[key] = {};
        for (let i = 0; i < variationList[key].values.length; i += 1) {
            if (variationList[key].values[i].toLowerCase().includes(target.value.toLowerCase())) {
                newArray.push(variationList[key].values[i]);
            }
        }

        newList[key].title = variationList[key].title;
        newList[key].values = newArray;
        return newArray;
    });

    setVariationListFiltered(newList);
};

export default function ProductVariationModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const deviceClassName = `device-${deviceSize(useTheme())}`;
    const productDetails = useSelector((state) => state.productDetails);
    const [variationList, setVariationList] = useState({});
    const [variationListFiltered, setVariationListFiltered] = useState({});
    const shopInfo = useSelector((state) => state.shopInfo);
    const [variants, setVariants] = useState(productDetails?.variation_header || []);
    const dispatch = useDispatch();
    const history = useHistory();
    const productId = useParams();

    const handleSearch = (event) => {
        filter(variationList, event.target, setVariationListFiltered);
    };

    const handleToggle = (value) => () => {
        const newVariants = { ...variants };

        if (!variants[value]) {
            newVariants[value] = [];
        } else {
            delete newVariants[value];
        }

        setVariants(newVariants);
    };

    const handleToggleSub = (key, value) => () => {
        const currentIndex = variants[key]?.indexOf(value);
        const newVariants = { ...variants };

        if (currentIndex === -1) {
            newVariants[key].push(value);
        } else {
            newVariants[key].splice(currentIndex, 1);
        }

        setVariants(newVariants);
    };

    const handleNextClick = () => {
        updatingVariantProduct(
            { productVariant: variants, shopId: shopInfo.id },
            productId,
            (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    ProductDataUpdateDispatch(dispatch, {
                        variation_header: variants,
                    });
                    history.push(exiturl);
                }
            }
        );
    };

    useEffect(() => {
        getVariationList((response) => {
            setVariationList(response.data);
            setVariationListFiltered(response.data);
        });
    }, []);

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-variant"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={productDetails.title}
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Set Variation
                        </Typography>

                        <TextField
                            label="Search Variation"
                            variant="outlined"
                            fullWidth
                            type="text"
                            helperText="Search for Variation"
                            onChange={handleSearch}
                            size="small"
                        />

                        {Object.keys(variationListFiltered).length ? (
                            <VariationOption
                                data={variationListFiltered}
                                handleToggle={handleToggle}
                                handleToggleSub={handleToggleSub}
                                variants={variants}
                            />
                        ) : null}
                    </div>

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={[variants]}
                        handleNextClick={handleNextClick}
                        skip
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
