import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import ProductData from '../../../../configs/ProductData';
import DetectDevice from '../../../utils/DetectDevice';

const useStyles = makeStyles(() => ({
    table: {
        minWidth: 650,
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
        borderRadius: '50%',
    },
    tableContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(33, 43, 54)',
        transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 0,
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
}));

export default function CustomerManageSection() {
    const classes = useStyles();
    const handleChangePage = () => {};
    return (
        <DetectDevice>
            <TableContainer className={classes.tableContainer} type="lg" component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow className="tableTr">
                            <TableCell>
                                <Checkbox inputProps={{ 'aria-label': 'secondary checkbox' }} />
                            </TableCell>
                            <TableCell>Name</TableCell>

                            <TableCell align="center">Category</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ProductData.map((row) => (
                            <TableRow
                                key={row.id}
                                className={[classes.tableRowHover, 'tableTr '].join(' ')}
                            >
                                <TableCell align="center" width="10">
                                    <Checkbox inputProps={{ 'aria-label': 'secondary checkbox' }} />
                                </TableCell>
                                <TableCell scope="row">
                                    <IconButton
                                        edge="end"
                                        aria-label="user"
                                        aria-haspopup="true"
                                        color="inherit"
                                    >
                                        <Avatar
                                            className={classes.purple}
                                            style={{ margin: '0px auto' }}
                                        >
                                            KB
                                        </Avatar>
                                    </IconButton>
                                    <b style={{ paddingLeft: '20px' }}>{row.title}</b>
                                </TableCell>
                                <TableCell align="center">{row.title}</TableCell>
                                <TableCell align="center">{row.price}</TableCell>
                                <TableCell align="center" width="10">
                                    <IconButton>
                                        <MoreVertIcon className="btn-hover" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={2}
                    rowsPerPage={10}
                    page={0}
                    onChangePage={handleChangePage}
                />
            </TableContainer>

            <div className="row" type="sm|xs">
                {ProductData.map((row) => (
                    <div className="col-12 productMobile-card-col" key={row.id}>
                        <Card className={classes.root}>
                            <CardContent>
                                <div className="row">
                                    <div className="col-2">
                                        <IconButton
                                            edge="end"
                                            aria-label="user"
                                            aria-haspopup="true"
                                            color="inherit"
                                        >
                                            <Avatar
                                                className={classes.purple}
                                                style={{ margin: '0px auto' }}
                                            >
                                                KB
                                            </Avatar>
                                        </IconButton>
                                    </div>
                                    <div className="col-5">
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            {row.title}
                                        </Typography>
                                    </div>
                                    <div className="col-2">
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            Paid
                                        </Typography>
                                    </div>
                                    <div className="col-3">
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            Status
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                ))}
            </div>
        </DetectDevice>
    );
}
