import { Card, CardContent, TextField } from '@material-ui/core';
import React from 'react';

export default function SettingManageAccountPage() {
    return (
        <div className="row">
            <div className="col-12">
                <h5>Account Information</h5>
            </div>
            <div className="col-12 col-lg-4">
                <Card>
                    <CardContent>
                        <h6 className="mb-2 mt-2 field-title">Name</h6>
                        <TextField required label="Name" variant="outlined" fullWidth />
                        <h6 className="mb-2 mt-2 field-title">Email</h6>
                        <TextField required label="Email" variant="outlined" fullWidth />
                        <h6 className="mb-2 mt-2 field-title">Contact</h6>
                        <TextField required label="Contact" variant="outlined" fullWidth />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}
