import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProductDataDispatch from '../../../dispatches/ProductDataDispatch';
import Preview from '../../../utils/Preview';
import ProductDetailsSection from '../sections/ProductDetailsSection';
import ProductPageHeaderSection from '../sections/ProductPageHeaderSection';
import ProductUpdateModals from '../sections/ProductUpdateModals';

export default function ProductDetailsPage() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const shopInfo = useSelector((state) => state.shopInfo);

    useEffect(() => {
        ProductDataDispatch(id, dispatch, (productDetails) => {
            if (productDetails.id !== 0) {
                setShow(true);
            }
        });
    }, [dispatch, id, shopInfo.id]);

    return (
        <Preview placeholderModel="table" show={show}>
            <ProductPageHeaderSection />
            <ProductDetailsSection />
            <ProductUpdateModals />
        </Preview>
    );
}
