import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabList from '@material-ui/lab/TabList';
import React from 'react';

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function CampaignPropertyAppBar({ value, handleChange, classes }) {
    return (
        <AppBar position="static" style={{ background: '#fff', boxShadow: 'none' }}>
            <TabList
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab className={classes.tabStyle} label="Products" value="1" {...a11yProps(0)} />
            </TabList>
        </AppBar>
    );
}
