import store from '../store';

function NavDrawerToggle() {
    return {
        type: 'DRAWER_TOGGLE',
        payload: {},
    };
}
function NavDrawerOpen() {
    return {
        type: 'DRAWER_OPEN',
        payload: {},
    };
}
function NavDrawerClose() {
    return {
        type: 'DRAWER_CLOSE',
        payload: {},
    };
}

export default function NavDrawerToggleDispatch(dispatch, isOpen) {
    const currentState = store.getState().drawer;
    if (currentState !== isOpen) {
        if (isOpen === true) {
            dispatch(NavDrawerOpen());
        } else if (isOpen === false) {
            dispatch(NavDrawerClose());
        } else {
            dispatch(NavDrawerToggle());
        }
    }
}
