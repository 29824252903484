import React from 'react';
import CampaignManageHeaderSection from '../section/CampaignManageHeaderSection';
import CampaignManageModals from '../section/CampaignManageModals';
import CampaignManageSection from '../section/CampaignManageSection';

export default function CampaignManagePage() {
    return (
        <div>
            <CampaignManageHeaderSection />
            <CampaignManageSection />
            <CampaignManageModals />
        </div>
    );
}
