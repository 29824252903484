/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Card } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import { Link } from 'react-router-dom';
import data from '../../../../configs/ProductData';
import Optimizee from '../../../utils/Optimizee';
import OrderPageHeaderSection from '../section/OrderPageHeaderSection';

const useStyles = makeStyles(() => ({
    table: {
        minWidth: 650,
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
    },
    tableContainer: {
        overflow: 'hidden',
        padding: '10px',
        position: 'relative',
        zIndex: 0,
        boxShadow: 'none',
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
}));
export default function OrderManageDetailPage() {
    const classes = useStyles();
    return (
        <>
            <OrderPageHeaderSection headTitle="Order Detail" />
            <Card>
                <div className="row">
                    <div className="col-12 invoice-head">
                        <h3>INVOICE</h3>
                    </div>
                    <div className="col-12">
                        <div className="order-detail-section-order-no">
                            <span>Paid</span>
                            <br />
                            <h6>INV-49d9d551-cf6a-4ffe-9121-843d0f76e270</h6>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="order-detail-section-title">
                            <h3>INVOICE FROM</h3>
                            <p>Rickey Jaskolski</p>
                            <p>DieSachbearbeiter Choriner Straße 49 10435 Berlin</p>
                            <p>Phone: 583-476-6615</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="order-detail-section-title">
                            <h3>INVOICE TO</h3>
                            <p>Rickey Jaskolski</p>
                            <p>DieSachbearbeiter Choriner Straße 49 10435 Berlin</p>
                            <p>Phone: 583-476-6615</p>
                        </div>
                    </div>
                    <div className="col-12">
                        <TableContainer className={classes.tableContainer} component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="tableTr">
                                        <TableCell align="center">#</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell />
                                        <TableCell align="center">Qty</TableCell>
                                        <TableCell align="center">Unit Price</TableCell>
                                        <TableCell align="center">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.slice(0, 3).map((row) => (
                                        <TableRow
                                            key={row.id}
                                            className={[classes.tableRowHover, 'tableTr '].join(
                                                ' '
                                            )}
                                        >
                                            <TableCell align="center">{row.id}</TableCell>
                                            <TableCell style={{ width: '96px' }}>
                                                <Link to={`/orders/${row.id}/details/`}>
                                                    <img
                                                        className={classes.image}
                                                        src={Optimizee(row.imgUrl, 100)}
                                                        alt={row.title}
                                                    />
                                                </Link>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Link to={`/orders/${row.id}/details/`}>
                                                    <b> {row.title}</b>
                                                    <p style={{ fontSize: '10px' }}>SUK {row.id}</p>
                                                </Link>
                                            </TableCell>
                                            <TableCell align="center">2</TableCell>
                                            <TableCell align="center">{row.price}</TableCell>
                                            <TableCell align="center" width="10">
                                                {row.price * 2}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan="5" align="right">
                                            Subtotal
                                        </TableCell>
                                        <TableCell align="center">9000.00</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="5" align="right">
                                            Discount
                                        </TableCell>
                                        <TableCell align="center">00.00</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="5" align="right">
                                            Shipping
                                        </TableCell>
                                        <TableCell align="center">00.00</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="5" align="right">
                                            Total
                                        </TableCell>
                                        <TableCell align="center">9000.00</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        <div className="order-detail-section-print-btn">
                            <Link to="/print" target="_blank">
                                <h6>
                                    <VisibilityIcon style={{ fontSize: '18px' }} /> Preview
                                </h6>
                            </Link>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}
