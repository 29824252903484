import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { Link } from 'react-router-dom';
import DetectDevice from '../../../utils/DetectDevice';
import Optimizee from '../../../utils/Optimizee';
import OrderCard from '../comps/OrderCard';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
        borderRadius: '50%',
    },
    tableContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(33, 43, 54)',
        transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 0,
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function OrderManageListSection({ data }) {
    const classes = useStyles();

    return (
        <DetectDevice>
            <TableContainer className={classes.tableContainer} component={Paper} type="lg|md">
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow className="tableTr">
                            <TableCell>
                                <Checkbox />
                            </TableCell>
                            <TableCell>Item</TableCell>
                            <TableCell />
                            <TableCell align="center">Category</TableCell>
                            <TableCell align="center">Price</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow
                                key={row.id}
                                className={[classes.tableRowHover, 'tableTr '].join(' ')}
                            >
                                <TableCell align="center" width="10">
                                    <Checkbox inputProps="aria-labelledby" />
                                </TableCell>
                                <TableCell style={{ width: '96px' }}>
                                    <Link to={`/orders/${row.id}/details/`}>
                                        <img
                                            className={classes.image}
                                            src={Optimizee(row.imgUrl, 100)}
                                            alt={row.title}
                                        />
                                    </Link>
                                </TableCell>
                                <TableCell align="left">
                                    <Link to={`/orders/${row.id}/details/`}>
                                        <b> {row.title}</b>
                                        <p style={{ fontSize: '10px' }}>SUK {row.id}</p>
                                    </Link>
                                </TableCell>
                                <TableCell align="center">{row.title}</TableCell>
                                <TableCell align="center">{row.mrp.toFixed(2)}</TableCell>
                                <TableCell align="center">
                                    {' '}
                                    <Chip size="small" label="Paid" color="primary" />
                                </TableCell>
                                <TableCell align="center" width="10">
                                    <MoreVertIcon />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={2}
                    rowsPerPage={10}
                    page={1}
                />
            </TableContainer>
            <div className="row" type="xs|sm">
                {data.map((row) => (
                    <div className="col-12 productMobile-card-col" key={row.id}>
                        <OrderCard data={row} />
                    </div>
                ))}
            </div>
        </DetectDevice>
    );
}
