import React from 'react';
import { useSelector } from 'react-redux';
import ProductOverviewCard from './productdetails/ProductOverviewCard';
import ProductPropertyCard from './productdetails/ProductPropertyCard';

export default function ProductDetailsSection() {
    const data = useSelector((state) => state.productDetails);

    const productCategoryList = useSelector((state) => state.categoryList);
    const thumbImages = data?.images || [];
    return (
        <>
            <ProductOverviewCard />

            <ProductPropertyCard
                data={data}
                productCategoryList={productCategoryList}
                thumbImages={thumbImages}
            />
        </>
    );
}
