/* eslint-disable prettier/prettier */
import { TextField, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ShopInfoUpdateDispatch } from '../../../dispatches/ShopInfoDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import {
    ProductModalFooter,
    ProductModalHeader
} from '../../products/modals/comp/ProductModalHeaderFooter';

const updatingCampaignDuration = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_CAMPAIGN_UPDATE_DURATION.replace(':campId', params.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log(e);
        });
};

export default function CampaignDurationModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const deviceClassName = `device-${deviceSize(useTheme())}`;
    const shopDetails = useSelector((state) => state.shopInfo);
    const campaignDetail = useSelector((state) => state.campaignDetail);
    const dispatch = useDispatch();
    const history = useHistory();

    const [startDate, setStartDate] = useState(campaignDetail.time_start);
    const [endDate, setEndDate] = useState(campaignDetail.time_end);
    const handleStartDate = (event) => {
        setStartDate(event.target.value);
        console.log(event.target.value);
    };
    const handleEndDate = (event) => {
        setEndDate(event.target.value);
    };
    const handleNextClick = () => {
        // console.dir(next);
        updatingCampaignDuration({ time_start:startDate, time_end:endDate ,shopId: shopDetails.id ,id:campaignDetail.id}, (response) => {
            DataErrors(response, dispatch);
            if (response.data.error === 0) {
                ShopInfoUpdateDispatch(dispatch, {
                    time_start:startDate,
                    time_end:endDate,
                });
                history.push(next);
            }
        });
    };

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader prev={prev} step={step} exiturl={exiturl} title={campaignDetail.campaign_title}/>
                    <div className="app-style-body">
                        <h4 className="mb-3 mt-2 field-title">Campaign Deration</h4>
                        <TextField
                            required
                            variant="outlined"
                            fullWidth
                            size="small"
                            id="datetime-local"
                            helperText="Please enter start date"
                            value={startDate}
                            onChange={handleStartDate}
                            style={{paddingBottom:'20px'}}
                            type="datetime-local"
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                        <TextField
                            required
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter end date"
                            value={endDate}
                            onChange={handleEndDate}
                           type="datetime-local"
                            InputLabelProps={{
                            shrink: true,
                            }}
                           
                        />
                    </div>

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={endDate}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
