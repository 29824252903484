import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CampaignDetailPage from '../app/campaigns/pages/CampaignDetailPage';
import CampaignManagePage from '../app/campaigns/pages/CampaignManagePage';

export default function CampaignRouters() {
    return (
        <Switch>
            <Route path="/:shopId/campaigns/:id/update/:modals">
                <CampaignDetailPage />
            </Route>
            <Route path="/:shopId/campaigns/:id/detail">
                <CampaignDetailPage />
            </Route>
            <Route path="/:shopId/campaigns">
                <CampaignManagePage />
            </Route>
        </Switch>
    );
}
