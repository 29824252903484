import React from 'react';
import { useDispatch } from 'react-redux';
import NavDrawerToggleDispatch from '../../../dispatches/NavDrawerToggleDispatch';
import store from '../../../store';
import ProductManageCategorySection from '../sections/ProductManageCategorySection';
import ProductPageCategoryHeaderSection from '../sections/ProductPageCategoryHeaderSection';

const pageTitle = 'Product Category';

export default function ProductCategoryPage() {
    const dispatch = useDispatch();
    NavDrawerToggleDispatch(dispatch, false);
    const { categoryList } = store.getState();

    // const catTree ={};
    // for(let x=0; x<=categoryList.length; x+=1){
    //     if(){

    //     }
    // }

    // --Page Build
    document.title = `${pageTitle} || ${process.env.REACT_APP_COMPANY_TITLE}`;

    return (
        <>
            <ProductPageCategoryHeaderSection />
            <ProductManageCategorySection categoryList={categoryList} />
        </>
    );
}
