import { combineReducers } from 'redux';
import BackdropCommonReducer from './reducers/BackdropCommonReducer';
import CampaignDetailReducer from './reducers/CampaignDetailReducer';
import CategoryReducer from './reducers/CategoryReducer';
import NavDrawerReducer from './reducers/NavDrawerReducer';
import NavMenuReducer from './reducers/NavMenuReducer';
import ProductDetailsReducer from './reducers/ProductDetailsReducer';
import ShopInfoReducer from './reducers/ShopInfoReducer';
import SnackbarCommonReducer from './reducers/SnackbarCommonReducer';
// import LoginReducer from './project/modules/system/login/LoginReducer';
// import iceCreamReducer from './iceCream/iceCreamReducer';
// import userReducer from './user/userReducer';
const rootReducer = combineReducers({
    // cart: CartReducer,
    drawer: NavDrawerReducer,
    activeMenu: NavMenuReducer,
    activeSnackbar: SnackbarCommonReducer,
    activeBackdrop: BackdropCommonReducer,
    productDetails: ProductDetailsReducer,
    categoryList: CategoryReducer,
    shopInfo: ShopInfoReducer,
    campaignDetail: CampaignDetailReducer,

    // allProducts:
    // iceCream: iceCreamReducer,
    // user: userReducer,
});

export default rootReducer;
