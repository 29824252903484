import React from 'react';

const pageTitle = 'Product Stock';

// General Parameter of Each Page
// 1. Title Set
// 2. Data Load (From DB) In a single File
// 3. Spinner until load Data

export default function ProductStockPage() {
    // --Page Build
    document.title = `${pageTitle} || ${process.env.REACT_APP_COMPANY_TITLE}`;
    return <div>Stock Page</div>;
}
