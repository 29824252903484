/* eslint-disable global-require */
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DraftsIcon from '@material-ui/icons/Drafts';
import LanguageIcon from '@material-ui/icons/Language';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PhoneIcon from '@material-ui/icons/Phone';
import TabPanel from '@material-ui/lab/TabPanel';
import React from 'react';
import { Link } from 'react-router-dom';

export default function ShopDetailPropertyAbout({ classes }) {
    return (
        <TabPanel value="4">
            <div className="row">
                <div className="col-8">
                    <h6>Description</h6>
                    <p>
                        We love construction and we want the whole world to love it too! Hosted by
                        Fred Mills, The B1M is the world’s largest and most subscribed-to video
                        channel for construction. We bring you new videos EVERY Wednesday at 12 noon
                        GMT // 8am Eastern. Our mission is to fundamentally change the perception of
                        construction on planet Earth, attracting more people to this incredible
                        industry.
                    </p>
                </div>
                <div className="col-4">
                    <List className={classes.root}>
                        <ListItem button>
                            <ListItemIcon>
                                <NotificationsNoneIcon />
                            </ListItemIcon>
                            <ListItemText primary="Notification" />
                            <Badge badgeContent={4} color="error" />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <DraftsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Messages" />
                            <Badge badgeContent={5} color="error" />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <PhoneIcon />
                            </ListItemIcon>
                            <ListItemText primary="01934335477" />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <LanguageIcon />
                            </ListItemIcon>
                            <Link to="." className="link">
                                <ListItemText primary="www.rupkotha.com" />
                            </Link>
                        </ListItem>
                    </List>
                </div>
            </div>
        </TabPanel>
    );
}
