import { Card, CardContent, makeStyles } from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import React from 'react';
import CampaignPropertyAppBar from './CampaignPropertyAppBar';
import CampaignPropertyProduct from './CampaignPropertyProduct';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: '20px',
        width: '100%',
    },
    tabStyle: {
        color: 'black',
    },
}));

function ProductDescriptionVariant({ data }) {
    if (data) {
        return Object.keys(data).map((key) => (
            <p key={key}>
                {key} :
                {data[key].map((item) => (
                    <b style={{ padding: '5px' }} key={item}>
                        {item},
                    </b>
                ))}
            </p>
        ));
    }

    return null;
}

export default function CampaignDetailPropertyInfo({ productData }) {
    const classes = useStyles();
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Card style={{ borderRadius: '16px', marginTop: 10 }} variant="outlined">
            <CardContent>
                <div className={classes.root}>
                    <TabContext value={value}>
                        <CampaignPropertyAppBar
                            handleChange={handleChange}
                            classes={classes}
                            ProductDescriptionVariant={ProductDescriptionVariant}
                            value={value}
                        />
                        <CampaignPropertyProduct productData={productData} />
                    </TabContext>
                </div>
            </CardContent>
        </Card>
    );
}
