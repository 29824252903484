import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabList from '@material-ui/lab/TabList';
import React from 'react';

export default function ShopDetailPropertyAppBar({ handleChange, classes }) {
    return (
        <AppBar position="static" style={{ background: '#fff', boxShadow: 'none' }}>
            <TabList onChange={handleChange} aria-label="simple tabs example">
                <Tab className={classes.tabStyle} label="Home" value="1" />
                <Tab className={classes.tabStyle} label="Products" value="2" />
                <Tab className={classes.tabStyle} label="Feedback" value="3" />
                <Tab className={classes.tabStyle} label="About" value="4" />
            </TabList>
        </AppBar>
    );
}
