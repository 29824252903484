const initialState = {};

const CampaignDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CAMPAIGN_DETAILS_SET': {
            return action.payload;
        }

        default:
            return state;
    }
};

export default CampaignDetailReducer;
