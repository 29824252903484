import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function CampaignModalUpdateMenu({ anchor, openMenu, handleClose, id }) {
    const shopInfo = useSelector((state) => state.shopInfo);
    const shopId = shopInfo.id;
    return (
        <Menu
            id="long-menu"
            anchorEl={anchor}
            open={openMenu}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <MenuItem onClick={handleClose}>
                <Link to={`/${shopId}/campaigns/${id}/update/title/`}>Update Title </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <Link to={`/${shopId}/campaigns/${id}/update/duration/`}>Update Duration</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <Link to={`/${shopId}/campaigns/${id}/update/discount/`}>Update Discount</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <Link to={`/${shopId}/campaigns/${id}/update/description/`}>
                    Update Description
                </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <Link to={`/${shopId}/campaigns/${id}/update/images/`}>Update Banner</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <Link to={`/${shopId}/campaigns/${id}/update/products/`}>Update Products</Link>
            </MenuItem>
        </Menu>
    );
}
