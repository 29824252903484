import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import shopCover from '../../../../../assets/images/shop-cover.jpg';
import ShopModalUpdateMenu from '../../modals/comp/ShopModalUpdateMenu';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    rootTab: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: '20px',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    tabStyle: {
        color: 'black',
    },
}));
export default function ShopDetailOverViewInfo({ shopDetails }) {
    const classes = useStyles();
    return (
        <>
            <Grid item xs={12}>
                <div className="shop-detail-cover">
                    <img src={shopCover} alt="Shop Cover" />
                </div>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
                <div className="shop-detail-title">
                    <Avatar className={classes.large}>{shopDetails.shop_name.slice(0, 1)}</Avatar>
                    <div>
                        <h6>{shopDetails.shop_name}</h6>
                        <p>@{shopDetails.id}</p>
                    </div>
                    <div className="shop-detail-title-menu">
                        <ShopModalUpdateMenu id={shopDetails.id} />
                    </div>
                </div>
            </Grid>
        </>
    );
}
