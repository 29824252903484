/* eslint-disable global-require */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TabContext from '@material-ui/lab/TabContext';
import React from 'react';
import ShopDetailPropertyAbout from './ShopDetailPropertyAbout';
import ShopDetailPropertyAppBar from './ShopDetailPropertyAppBar';
import ShopDetailPropertyHome from './ShopDetailPropertyHome';
import ShopDetailPropertyProduct from './ShopDetailPropertyProduct';
import ShopDetailPropertyReview from './ShopDetailPropertyReview';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    rootTab: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: '20px',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    tabStyle: {
        color: 'black',
    },
}));

export default function ShopDetailPropertyInfo({ shopDetails, productData }) {
    const classes = useStyles();
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Grid item xs={12}>
            <div className={classes.rootTab}>
                <TabContext value={value}>
                    <ShopDetailPropertyAppBar handleChange={handleChange} classes={classes} />
                    <ShopDetailPropertyHome shopDetails={shopDetails} />
                    <ShopDetailPropertyProduct productData={productData} />
                    <ShopDetailPropertyReview />
                    <ShopDetailPropertyAbout classes={classes} />
                </TabContext>
            </div>
        </Grid>
    );
}
