import React from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import ProductCategoryPage from '../app/products/pages/ProductCategoryPage';
import ProductDetailsPage from '../app/products/pages/ProductDetailsPage';
import ProductManagePage from '../app/products/pages/ProductManagePage';
import ProductStockPage from '../app/products/pages/ProductStockPage';

export default function ProductRouters() {
    const { shopId } = useParams();

    return (
        <Switch>
            <Route path="/:shopId/products" exact>
                <Redirect to={`/${shopId}/products/manage/`} />
            </Route>
            <Route path="/:shopId/products/manage">
                <ProductManagePage />
            </Route>
            <Route path="/:shopId/products/:id/details">
                <ProductDetailsPage />
            </Route>
            <Route path="/:shopId/products/:id/update/:modals">
                <ProductDetailsPage />
            </Route>
            <Route path="/:shopId/products/stock">
                <ProductStockPage />
            </Route>
            <Route path="/:shopId/products/category">
                <ProductCategoryPage />
            </Route>
        </Switch>
    );
}
