const initialState = false;

const BackdropCommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_BACKDROP': {
            return action.payload.isOpen;
        }
        default:
            return state;
    }
};

export default BackdropCommonReducer;
