/* eslint-disable no-unused-vars */
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TwitterIcon from '@material-ui/icons/Twitter';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ProductModalUpdateMenu from '../../modals/comp/ProductModalUpdateMenu';

function ProductSize({ size }) {
    return (
        <Button variant="outline-info" className="product-detail-sizeBtn">
            {size}
        </Button>
    );
}
function ProductAgeRange({ age }) {
    return (
        <Button variant="outline-info" className="product-detail-ageBtn">
            {age}
        </Button>
    );
}
function ProductColor({ color }) {
    return (
        <Button style={{ background: color, border: 'none' }} className="product-detail-colorBtn" />
    );
}

function ShowProductDetailsHeader({ data, handleClick, open, anchorEl, handleClose }) {
    const shopInfo = useSelector((state) => state.shopInfo);

    return (
        <div className="product-detail-header">
            <strong className="product-detail-title">
                {data.title}
                <IconButton
                    aria-label="delete"
                    style={{ float: 'right' }}
                    onClick={handleClick}
                    color="primary"
                >
                    <MoreVertIcon />
                </IconButton>
                <ProductModalUpdateMenu
                    anchor={anchorEl}
                    openMenu={open}
                    handleClose={handleClose}
                    id={data.id}
                />
            </strong>

            <div className="ProductDetailBorder" />
        </div>
    );
}

function ShowCategories({ data, productCategoryList }) {
    return (
        <div className="product-detail-price">
            <h6>Category :</h6>

            {data.categories
                ? data.categories.map((cat) => (
                      <Chip
                          label={
                              productCategoryList
                                  .filter((e) => e.categoryId === cat)
                                  .map((en) => en.view)[0]
                          }
                          color="secondary"
                          size="small"
                          variant="outlined"
                          className="chip-style-category"
                          style={{ margin: '5px' }}
                          key={cat}
                      />
                  ))
                : 'Not Set'}
        </div>
    );
}

function ShowPrices({ data }) {
    return (
        <div className="product-detail-price">
            <h6>Price :</h6>
            <strong>৳ {data.price_sale}</strong>
            <span>৳ {data.price_purchase}</span>
        </div>
    );
}

function ProductVariant({ data }) {
    return (
        data &&
        Object.keys(data).map((items) => (
            <div key={items}>
                {items === 'color' && (
                    <div className="product-detail-color">
                        <h6 className="product-detail-size-title">Color:</h6>
                        {data[items].map((item) => (
                            <ProductColor color={item} key={item} />
                        ))}
                    </div>
                )}
                {items === 'ageRange' && (
                    <div className="product-detail-age">
                        <h6 className="product-detail-size-title">Age:</h6>
                        {data[items].map((item) => (
                            <ProductAgeRange age={item} key={item} />
                        ))}
                    </div>
                )}
                {items === 'size' && (
                    <div className="product-detail-size">
                        <h6 className="product-detail-size-title">Size:</h6>
                        {data[items].map((item) => (
                            <ProductSize size={item} key={item} />
                        ))}
                    </div>
                )}
            </div>
        ))
    );
}

function ShowShareButtons() {
    return (
        <div className="product-detail-social-link">
            <FacebookIcon className="link" />
            <InstagramIcon className="link" />
            <TwitterIcon className="link" />
            <LinkedInIcon className="link" />
        </div>
    );
}

export default function ProductOverviewInfo({ data }) {
    const productCategoryList = useSelector((state) => state.categoryList);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <ShowProductDetailsHeader
                data={data}
                handleClick={handleClick}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
            />

            <ShowCategories data={data} productCategoryList={productCategoryList} />

            <ShowPrices data={data} />

            <ProductVariant data={data.variation_header} />

            <ShowShareButtons />
        </>
    );
}
