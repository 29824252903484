import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavDrawerToggleDispatch from '../../dispatches/NavDrawerToggleDispatch';
import store from '../../store';
import DetectDevice from '../../utils/DetectDevice';
import NavBarItems from '../combined/navdrawer/NavDrawerItems';

const drawerWidth = parseInt(process.env.REACT_APP_NAV_DRAWER_WIDTH, 10);

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
}));

export default function NavigationDrawerSection({ shopId, window }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const mobileOpen = useSelector((state) => state.drawer);
    const container = window !== undefined ? () => window().document.body : undefined;
    const [open, setOpen] = useState(mobileOpen);

    const handleDrawerToggle = () => {
        NavDrawerToggleDispatch(dispatch, false);
    };

    useEffect(() => {
        const unsubscribeMe = store.subscribe(() => {
            setOpen(store.getState().drawer);
        });
        // effect
        return () => {
            unsubscribeMe();
        };
    });

    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            <DetectDevice>
                <Drawer
                    type="xs"
                    container={container}
                    variant="temporary"
                    anchor="left"
                    open={open}
                    onClose={handleDrawerToggle}
                >
                    <NavBarItems shopId={shopId} />
                </Drawer>
                <Drawer
                    type="sm|md|lg|xl"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    <NavBarItems shopId={shopId} />
                </Drawer>
            </DetectDevice>
        </nav>
    );
}
