import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';

export default function PreviewCategories({ categoryList, categories }) {
    const categoryText = [];

    categories.forEach((categoryId) => {
        categoryList.forEach((categoryItem) => {
            if (categoryItem.categoryId === categoryId) {
                categoryText.push(categoryItem.view);
            }
        });
    });

    if (categoryText.length === 1) {
        return <Typography variant="subtitle2">{categoryText[0]}</Typography>;
    }
    if (categoryText.length > 1) {
        return (
            <Typography variant="subtitle2" color="textSecondary">
                {categoryText[0]} &{' '}
                <Tooltip
                    title={
                        <div className="text-center">
                            {categoryText.map((item) => (
                                <div key={Math.random()}>{item}</div>
                            ))}
                        </div>
                    }
                    arrow
                >
                    <span className="btn-more">{categoryText.length - 1} More</span>
                </Tooltip>
            </Typography>
        );
    }

    return null;
}
