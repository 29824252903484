import Avatar from '@material-ui/core/Avatar';
import { deepPurple } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import AppBarUserInfo from './AppBarUserInfo';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
}));

export default function AppBarUserAvatar() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let open = false;
    if (anchorEl) {
        open = true;
    }

    return (
        <>
            <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleOpen}
                color="inherit"
            >
                <Avatar className={classes.purple} style={{ margin: '0px auto' }}>
                    KB
                </Avatar>
            </IconButton>
            <AppBarUserInfo open={open} anchorEl={anchorEl} handleClose={handleClose} />
        </>
    );
}
