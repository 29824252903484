import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import StepHandler from '../../../utils/StepHandler';
import CampaignCreateModal from '../modals/CampaignCreateModal';
import CampaignDescriptionSetModal from '../modals/CampaignDescriptionSetModal';
import CampaignDiscountSetModal from '../modals/CampaignDiscountSetModal';
import CampaignDurationModal from '../modals/CampaignDurationModal';
import CampaignImageSetModal from '../modals/CampaignImageSetModal';
import CampaignProductSetModal from '../modals/CampaignProductSetModal';

export default function CampaignManageModals() {
    const location = useLocation();
    const shopInfo = useSelector((state) => state.shopInfo);

    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopInfo.id}/campaigns/`}>
            <CampaignCreateModal path={`/${shopInfo.id}/campaigns/create/`} />
            <CampaignDurationModal path={`/${shopInfo.id}/campaigns/:id/update/duration/`} />
            <CampaignDiscountSetModal path={`/${shopInfo.id}/campaigns/:id/update/discount/`} />
            <CampaignDescriptionSetModal
                path={`/${shopInfo.id}/campaigns/:id/update/description/`}
            />
            <CampaignImageSetModal path={`/${shopInfo.id}/campaigns/:id/update/images/`} />
            <CampaignProductSetModal path={`/${shopInfo.id}/campaigns/:id/update/products/`} />
        </StepHandler>
    );
}
