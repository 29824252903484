import { TextField, Typography, useTheme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const updatingCategoryProduct = (params, productId, callback) => {
    AxiosAuth.post(ConfigApi.API_PRODUCT_CAT_UPDATE.replace(':productId', productId.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log(e);
        });
};

const ShowCategory = ({ category, categoryList, setCategory }) => {
    if (category) {
        return (
            <>
                {category.map((cat) => (
                    <Chip
                        label={
                            categoryList.filter((e) => e.categoryId === cat).map((e) => e.view)[0]
                        }
                        onDelete={() => {
                            setCategory(category.filter((item) => item !== cat));
                        }}
                        color="secondary"
                        size="small"
                        variant="outlined"
                        className="chip-style-category"
                        style={{ margin: '5px' }}
                        key={cat}
                    />
                ))}
            </>
        );
    }

    return null;
};

export default function ProductCategorySetModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const deviceClassName = `device-${deviceSize(useTheme())}`;

    const dispatch = useDispatch();
    const history = useHistory();
    const productId = useParams();
    const categoryList = useSelector((state) => state.categoryList);
    const productDetails = useSelector((state) => state.productDetails);
    const shopInfo = useSelector((state) => state.shopInfo);
    const [category, setCategory] = useState(productDetails.categories);

    const handleCategory = (event, newValue) => {
        if (newValue?.categoryId && category.indexOf(newValue.categoryId) === -1) {
            const newState = [...category, newValue.categoryId];
            setCategory(newState);
        }
    };

    const handleNextClick = () => {
        // console.dir(next);
        updatingCategoryProduct(
            { productCategory: category, shopId: shopInfo.id },
            productId,
            (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    // --Update Store
                    ProductDataUpdateDispatch(dispatch, {
                        categories: category,
                    });
                    history.push(next);
                }
            }
        );
    };

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={productDetails.title}
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Set Product Category
                        </Typography>

                        <Autocomplete
                            options={categoryList}
                            getOptionLabel={(option) => option.view}
                            getOptionSelected={(option) => option.categoryId}
                            fullWidth
                            selectOnFocus={false}
                            clearOnBlur
                            onChange={handleCategory}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value=""
                                    size="small"
                                    required
                                    label="Product Category"
                                    variant="outlined"
                                    fullWidth
                                    helperText="Please enter the product category"
                                />
                            )}
                        />

                        <ShowCategory
                            category={category}
                            categoryList={categoryList}
                            setCategory={setCategory}
                        />
                    </div>

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={category}
                        handleNextClick={handleNextClick}
                        skip
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
