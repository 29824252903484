import { Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../store';

function SnackbarClose() {
    return {
        type: 'SET_CLOSE_SNACKBAR',
        payload: {},
    };
}

export default function SnackbarCommon() {
    const dispatch = useDispatch();
    const sbInfo = useSelector((state) => state.activeSnackbar);
    const [open, setOpen] = useState(sbInfo.isOpen);

    const handleSnackbarClose = () => {
        dispatch(SnackbarClose());
    };

    useEffect(() => {
        const unsubscribeMe = store.subscribe(() => {
            setOpen(store.getState().activeSnackbar.isOpen);
        });
        // effect
        return () => {
            unsubscribeMe();
        };
    }, []);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            autoHideDuration={5000}
            message={sbInfo.message}
            action={<CloseIcon onClick={handleSnackbarClose} />}
            onClose={() => setOpen(false)}
        />
    );
}
