/* eslint-disable prettier/prettier */
import {
    TextField,
    // eslint-disable-next-line prettier/prettier
    useTheme
} from '@material-ui/core';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ShopInfoUpdateDispatch } from '../../../dispatches/ShopInfoDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import {
    ProductModalFooter,
    ProductModalHeader
} from '../../products/modals/comp/ProductModalHeaderFooter';

const updatingAddressShop = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_SHOP_UPDATE_ADDRESS.replace(':shopId', params.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log(e);
        });
};

export default function ShopAddressModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const deviceClassName = `device-${deviceSize(useTheme())}`;

    const shopDetails = useSelector((state) => state.shopInfo);
    const [addressValue, setAddressValue] = React.useState(shopDetails.address);
    const [zipValue, setZipValue] = React.useState(shopDetails.zip_code);

    const dispatch = useDispatch();
    const history = useHistory();

    const handleAddressValue = (event) => {
        setAddressValue(event.target.value);
    };
    const handleZipValue = (event) => {
        setZipValue(event.target.value);
    };
    const handleNextClick = () => {
        // console.dir(next);
        updatingAddressShop(
            { shop_address: addressValue, shop_zip: zipValue ,id: shopDetails.id},
            
            (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    // --Update Store
                    ShopInfoUpdateDispatch(dispatch, {
                        address: addressValue,
                        zip_code: zipValue,
                        
                    });
                    history.push(next);
                }
            }
        );
    };
    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader prev={prev} step={step} exiturl={exiturl} title={shopDetails.shop_name}/>
                    <div className="app-style-body">
                        <h4 className="mb-2 mt-2 field-title">Shop Address</h4>
                        <TextField
                            required
                            label="ZIP Code"
                            variant="outlined"
                            fullWidth
                            helperText="Please enter the shop zip code"
                            value={zipValue}
                            onChange={handleZipValue}
                            style={{paddingBottom:'20px'}}
                        />
                        <TextField
                            required
                            label="Shop Address"
                            variant="outlined"
                            fullWidth
                            helperText="Please enter the shop address"
                            rows={10}
                            multiline
                            type="textarea"
                            value={addressValue}
                            onChange={handleAddressValue}
                        />
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={addressValue}
                        skip
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
