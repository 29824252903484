import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import { red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Optimizee from '../../../utils/Optimizee';
import PreviewCategories from '../comps/PreviewCategories';
import ProductExclusiveBtn from '../comps/ProductExclusiveBtn';
import ProductPublishBtn from '../comps/ProductPublishBtn';
import ProductModalUpdateMenu from '../modals/comp/ProductModalUpdateMenu';

const useStyles = makeStyles((theme) => ({
    root: {},
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        objectFit: 'cover',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

function ProductView({ data, categoryList }) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const shopInfo = useSelector((state) => state.shopInfo);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Card className={classes.root}>
            <CardHeader
                className="product-card-header"
                action={
                    <IconButton
                        aria-label="delete"
                        style={{ float: 'right' }}
                        onClick={handleClick}
                        color="primary"
                    >
                        <MoreVertIcon />
                    </IconButton>
                }
                title={
                    <Link to={`/${shopInfo.id}/products/${data.id}/details`}>
                        <Typography
                            variant="body2"
                            style={{ fontSize: '15px', fontWeight: 'bold' }}
                            color="textSecondary"
                            component="p"
                        >
                            {data.title}
                        </Typography>
                    </Link>
                }
                subheader={data.total_price ? `Price:${data.total_price}` : ''}
            />
            <ProductModalUpdateMenu
                anchor={anchorEl}
                openMenu={open}
                handleClose={handleClose}
                id={shopInfo.id}
            />
            <img className="product-card-image" src={Optimizee(data.default_image)} alt="" />
            <CardContent>
                Category:
                {data.categories.length > 0 ? (
                    <PreviewCategories categoryList={categoryList} categories={data.categories} />
                ) : (
                    ' Not Set'
                )}
            </CardContent>
            <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <ProductPublishBtn data={data} />
                </IconButton>
                <IconButton aria-label="add to favorites">
                    <ProductExclusiveBtn data={data} />
                </IconButton>
                <IconButton aria-label="share">
                    <Chip size="small" label="Active" color="primary" />
                </IconButton>
                <IconButton aria-label="share">
                    <Link to={`/${shopInfo.id}/products/${data.id}/details`}>
                        <OpenInNewIcon />
                    </Link>
                </IconButton>

                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography paragraph>Offer Price: 0.00</Typography>
                    <Typography paragraph>Sale Price: 0.00</Typography>
                    <Typography paragraph>Stock: 0</Typography>
                    <Typography paragraph>Rating: 4.9/5.0</Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default function ProductListMobileView({ data, categoryList }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            {(rowsPerPage > 0
                ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : data
            ).map((row) => (
                <div className="col-12 productMobile-card-col" key={row.id}>
                    <ProductView data={row} categoryList={categoryList} />
                </div>
            ))}

            <TablePagination
                rowsPerPageOptions={[50, 100, { label: 'All', value: -1 }]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    );
}
