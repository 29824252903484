import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OrderManageCanceledPage from '../app/orders/pages/OrderManageCanceledPage';
import OrderManageDeliveredPage from '../app/orders/pages/OrderManageDeliveredPage';
import OrderManageDetailPage from '../app/orders/pages/OrderManageDetailPage';
import OrderManagePendingPage from '../app/orders/pages/OrderManagePendingPage';
import OrderManagePreparedPage from '../app/orders/pages/OrderManagePreparedPage';
import OrderManageProcessingPage from '../app/orders/pages/OrderManageProcessingPage';
import OrderManageRecentPage from '../app/orders/pages/OrderManageRecentPage';
import OrderManageRefundPage from '../app/orders/pages/OrderManageRefundPage';
import OrderManageShippedPage from '../app/orders/pages/OrderManageShippedPage';

export default function OrderRouters() {
    return (
        <Switch>
            <Route path="/shopId:/orders/:id/details">
                <OrderManageDetailPage />
            </Route>
            <Route path="/shopId:/orders/recent">
                <OrderManageRecentPage />
            </Route>
            <Route path="/shopId:/orders/pending">
                <OrderManagePendingPage />
            </Route>
            <Route path="/shopId:/orders/processing">
                <OrderManageProcessingPage />
            </Route>
            <Route path="/shopId:/orders/prepared">
                <OrderManagePreparedPage />
            </Route>
            <Route path="/shopId:/orders/shipped">
                <OrderManageShippedPage />
            </Route>
            <Route path="/shopId:/orders/delivered">
                <OrderManageDeliveredPage />
            </Route>
            <Route path="/shopId:/orders/canceled">
                <OrderManageCanceledPage />
            </Route>
            <Route path="/shopId:/orders/refunded">
                <OrderManageRefundPage />
            </Route>
        </Switch>
    );
}
