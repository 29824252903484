function SnackbarOpen(message) {
    return {
        type: 'SET_OPEN_SNACKBAR',
        payload: {
            message,
        },
    };
}

export default function SnackbarOpenDispatch(dispatch, message) {
    dispatch(SnackbarOpen(message));
}
