import React, { cloneElement } from 'react';

export default function StepHandler({ pathname, children, exiturl }) {
    const paths = children.map((item) => item.props?.path);

    for (let i = 0; i <= children.length; i += 1) {
        if (pathname === children[i]?.props?.path) {
            return cloneElement(children[i], {
                pathname,
                count: children.length,
                step: i,
                paths,
                prev: paths[i - 1],
                next: paths[i + 1],
                show: true,
                exiturl,
            });
        }
    }

    return <></>;
}
